<template>
  <div class="app-container">
    <div class="title">本堂课出勤率: {{ sign_in_rate }} %</div>
    <el-button
      type="primary"
      @click="OneClickSignIn()"
      style="margin-bottom: 10px"
    >
      一键签到
    </el-button>
    <!-- <div>
    </div> -->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @cell-click="cellClick"
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.account }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.name }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes.college.school.name }}
        </template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.classes.school_year +
            "~" +
            (scope.row.classes.school_year - 0 + 1)
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="学期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.semester }}
        </template>
      </el-table-column> -->
      <el-table-column label="院系" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes.college.name }}
        </template>
      </el-table-column>
      <el-table-column label="年纪" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes.grade.name }}
        </template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes.name }}
        </template>
      </el-table-column>
      <el-table-column label="是否签到" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_sign_in == 1" type="success">签到</el-tag>
          <el-tag v-else type="danger">未签到</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="签到时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.one_schedule_sign_in_log
              ? scope.row.one_schedule_sign_in_log.created_at
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="出勤状态" min-width="110" align="center">
        <template slot-scope="scope">
          <template v-if="!scope.row.schedule_student_info"> - </template>
          <el-tag
            v-else-if="scope.row.schedule_student_info.status == 'miss_class'"
            type="danger"
          >
            旷课
          </el-tag>
          <el-tag
            v-else-if="scope.row.schedule_student_info.status == 'late'"
            type="warning"
          >
            迟到
          </el-tag>
          <template v-else> - </template>
        </template>
      </el-table-column>

      <el-table-column label="备注" min-width="110" align="center">
        <template slot-scope="scope">
          <el-input
            ref="gain"
            v-if="scope.row.isEdit_remark"
            @blur="blurClick(scope, scope.$index)"
            :autofocus="true"
            v-model="scope.row.remark"
          ></el-input>
          <span v-else>
            {{
              scope.row.schedule_student_info
                ? scope.row.schedule_student_info.remark
                : "-"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="110" align="center">
        <template #default="{ row:{is_sign_in,student_id} }">
          <el-button v-if="!is_sign_in" type="primary" @click="signHandler(student_id)"  >签到</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        class_id: null,
      },
      list: [],
      total: null,
      listLoading: false,
      sign_in_rate: 0,
      form: {
        id: "",
      },
    };
  },
  created() {
    this.listQuery.class_id = this.$route.query.class_id;
    this.listQuery.schedule_id = this.$route.query.schedule_id;
    this.getList();
  },
  methods: {
    OneClickSignIn() {
      request({
        url: "/api/schoolend/schedule/scheduleOneClickSignIn",
        method: "post",
        data: {
          schedule_id: this.listQuery.schedule_id - 0,
        },
      }).then(() => {
        this.$message({
          type: "success",
          message: "签到成功",
        });
        this.getList();
        this.$forceUpdate(); //强制更新
      });
    },
    cellClick(row, column, event) {
      console.log(row);
      if (column.label === "备注") {
        if (row.schedule_student_info) {
          this.$set(row, "remark", row.schedule_student_info.remark);
        } else {
          this.$set(row, "remark", null);
        }
        this.$set(row, "isEdit_remark", true);
      }
      this.$forceUpdate();
    },
    blurClick({ row, column }, index) {
      console.log(row);
      this.list.forEach((item) => {
        item.isEdit_remark = false;
        this.$forceUpdate();
      });
      if(!row.remark)return 
      request({
        url: "/api/schoolend/schedule/storeStudentRemark",
        method: "post",
        data: {
          schedule_id: this.listQuery.schedule_id - 0,
          class_student_id: row.id,
          remark: row.remark,
        },
      }).then(() => {
        this.getList();
        this.$forceUpdate(); //强制更新
      });
    },
    handleJobManagement() {
      this.$router.push(`/schedule/operation`);
    },
    handleStudent() {
      this.$router.push(`/schedule/student`);
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/school/classStudent",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.sign_in_rate = response.data.sign_in_rate || 0;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    async signHandler(id){
      try{
        console.log('获得id',id)
        await this.$confirm("确认为该学生签到？","警告",{type:"warning"})
        await request({
          url: "/api/schoolend/schedule/scheduleOneClickSignIn",
          method: "post",
          data: {
             schedule_id: Number(this.listQuery.schedule_id) ,
            student_id:id
          },
        })
        this.$message({message:"签到成功",type:"success"})
        this.getList();
      }catch(err){

      }
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 10px;
}
.tip_box {
  display: flex;
  align-items: center;
  .tip {
    width: 20px;
    height: 20px;
    margin: 0 20px 0 5px;
  }
  .color_1 {
    background: black;
  }
  .color_2 {
    background: #11eeee;
  }
  .color_3 {
    background: #4db34d;
  }
  .color_4 {
    background: red;
  }
}
</style>
